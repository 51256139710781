<template>
  <div class="list">
    <div class="page_bg">
      <div class="proj_name_text">{{ proj_name }}</div>
    </div>
    <div class="list_box">
      <div class="scheme_list" v-for="(item, index) in list" :key="index">
        <div v-if="item.subscheme.length > 0">
          <div class="scheme_item" @click="goCase(itm, 'sub')" v-for="(itm, idx) in item.subscheme" :key="idx">
            <div class="scheme_name">{{ itm.name }}</div>
            <div class="bg_box">
              <div class="icon_box">
                <van-icon name="arrow" color="#fff" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="scheme_item" @click="goCase(item, 'main')">
            <div class="scheme_name">{{ item.name }}</div>
            <div class="bg_box">
              <div class="icon_box">
                <van-icon name="arrow" color="#fff" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="plan" v-for="(item,index) in list" :key="index">
            <div class="title">{{item.name}}</div>
            <div class="explain">{{item.description}}</div>
            <div class="list">
                <template v-if="item.subscheme.length>0">
                    <div class="one" @click="goCase(itm,'sub')" v-for="(itm,idx) in item.subscheme">
                        <img :src="idx%2==0?'/static/planA.png':'/static/planB.png'" />
                        <div class="title">
                            <div class="content">{{itm.name}}</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="one" @click="goCase(item,'main')">
                        <img src="/static/planA.png" />
                        <div class="title">
                            <div class="content">{{item.name}}</div>
                        </div>
                    </div>
                </template>
            </div>
        </div> -->
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import {
  Toast, Dialog
} from "vant";
import {
  getScheme, recordCanSubmit, getLinkScheme, projectDoctor
} from '@/api/api'
import {
  mapGetters,
  mapState
} from 'vuex';
export default {
  name: '',
  data() {
    return {
      list: [],
      getCloseStatus: null, //获取是否关闭创建病例
      short_name: '', //项目简写
    }
  },
  computed: {
    ...mapState(['doctor_id', 'proj_name', 'basicInformation']),
    ...mapGetters(['operate_limit']),
  },
  mounted() {
    this.init()
  },
  methods: {
    confirm() {

    },

    async init() {
      this.short_name = this.basicInformation.projects[0].short_name
      this.list = await getScheme()
    },
    checkOpe(id) {
      // 检查操作是否禁用，id: 子方案id或方案id, type: 操作类型，返回true为可用
      return this.operate_limit.hasOwnProperty(id) && this.operate_limit[id].indexOf(1) !== -1 ? false : true
    },
    async goCase(item, type) {
      await projectDoctor({ doctor_id: this.userId, subproject_id: this.sub_id }).then(res => {
        console.log(item,'项目');
        //指定权限的可访问
        if(type === 'sub') {
          if(res.has_subschemes.length === 0  || (res.has_subschemes && res.has_subschemes.length > 0 && !res.has_subschemes.includes(+item.id))){
            Dialog.alert({
                message: `该项目暂未开放`,
                title: '提示',
                confirmButtonColor: '#000',
                showCancelButton: false,
                confirmButtonText: '确定'
            }).then(() => {
            })
            return false
          }
        }
        
        if (res.is_tip === 1) {
          Dialog.alert({
            message: `当前已上传病例数超过${res.tip_count}例，再上传则不再发放公益值.`,
            title: '提示',
            confirmButtonColor: '#000',
            showCancelButton: false,
            confirmButtonText: '确定'
          }).then(() => {
          })
        }
        if (res.is_tip === 1) {
          Dialog.alert({
            message: `当前已上传病例数超过${res.tip_count}例，再上传则不再发放公益值.`,
            title: '提示',
            confirmButtonColor: '#000',
            showCancelButton: false,
            confirmButtonText: '确定'
          }).then(() => {
          })
        }
        if (item.name == '精锐计划2.0') { //精锐计划2.0--5月5日0晨得开启上传
          console.log('精锐计划2.0不可点击');
          let currDate = new Date()
          let limitDate = new Date(2022, 4, 20, 0, 0, 0, 0)
          console.log('limitDate', limitDate);
          console.log('当前时间', currDate);
          if (currDate < limitDate) {
            Dialog.alert({
              title: '温馨提示',
              message: '精锐计划2.0将在5月20日00:00后开启上传',
              confirmButtonColor: '#4B99FF',
            })
            return
          }
        }
        this.getCloseStatus = getLinkScheme({
          params: {
            project_doctor_id: this.doctor_id
          }
        })
        //  1表示关闭；0表示未关闭 
        // return
        if (this.getCloseStatus.is_close_record == 1 || !this.checkOpe(item.id)) {
          console.log("关闭3333333333333333")
          Dialog.alert({
            title: "温馨提示",
            message: "应项目办要求项目上传通道暂时关闭",
            confirmButtonColor: "#4B99FF"
          }).then(() => {
            this.$router.replace({  //若关闭创建病例，则点确认后跳转到个人中心页
              path: "/me"
            })
          })
          return
        }
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true
        });
        let reqData = {
          project_doctor_id: this.doctor_id
        }
        if (type == 'sub') {
          reqData.scheme_id = item.scheme_id
          reqData.subscheme_id = item.id
        } else if (type == 'main') {
          reqData.scheme_id = item.id
          reqData.subscheme_id = ''
        }
        recordCanSubmit(reqData).then(res => {
          if (res.can) {
            if (this.short_name == 'xhzl') {
              console.log('消化肿瘤2.0需要先跳转到阅读知情同意书页面');
              this.$router.replace({
                name: 'informedConsent',
                query: {
                  scheme_id: type == 'sub' ? item.scheme_id : item.id,
                  subscheme_id: type == 'sub' ? item.id : '',
                  phase: item.phase,
                  enable: 1
                }
              })
            } else {
              this.$router.replace({
                path: '/case',
                query: {
                  scheme_id: type == 'sub' ? item.scheme_id : item.id,
                  subscheme_id: type == 'sub' ? item.id : '',
                  phase: item.phase,
                  enable: 1
                }
              })
            }

            Toast.clear()
          } else {
            Toast.clear()
            Toast('项目上传病例数已达上限。')
          }
        }).catch(err => {
          this.disabled = false
          Toast.clear()
          Toast(err.response.data.message)
        })
      })
    },
    back() {
      if (this.short_name == 'fcah') { //如果是肺肠安好项目则返回预填信息页面
        this.$router.replace({
          name: 'preFilling'
        })
      } else {
        this.$router.replace({
          path: '/sub_project'
        })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  // padding-top: 9px;
  box-sizing: border-box;

  .list_box {
    margin-top: -69px;
  }

  .scheme_list {

    // background: palegoldenrod;
    // margin-top: -69px;
    .scheme_item {
      // background: paleturquoise;
      background: #FFFFFF;
      display: flex;
      font-size: 16px;
      color: #292929;
      font-family: PingFangSC-Medium;
      justify-content: space-between;
      align-items: center;
      margin: 0 24px;
      box-shadow: 0px 4px 12px 0px rgba(22, 23, 24, 0.08);
      border-radius: 8px;
      height: 91px;
      margin-bottom: 16px;
      overflow: hidden;

      .scheme_name {
        margin-left: 32px;
      }

      .bg_box {
        width: 130px;
        height: 100%;
        background: url(/static/sub_bg.png) no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon_box {
          margin-left: 25px;
          background: #4B99FF;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .page_bg {
    width: 100%;
    height: 176px;
    background: url(/static/proj_center_bg.png);
    background-size: cover;

    .proj_name_text {
      font-size: 22px;
      color: #FFFFFF;
      font-family: PingFangSC-Medium;
      // background: #666;
      padding-top: 51px;
      padding-left: 28px;
      text-align: left;
    }
  }

  // .plan {
  //     margin-bottom: 15px;
  //     padding: 0 12px;
  //     text-align: left;

  //     .title {
  //         font-size: 14px;
  //         color: #232323;
  //         margin-bottom: 10px;
  //     }

  //     .explain {
  //         font-size: 11px;
  //         color: #909090;
  //         margin-bottom: 5px;
  //     }

  //     .list {
  //         width: 100%;
  //         display: flex;
  //         justify-content: space-between;
  //         flex-wrap: wrap;

  //         .one {
  //             width: 47%;
  //             position: relative;

  //             img {
  //                 width: 100%;
  //             }

  //             .title {
  //                 position: absolute;
  //                 top: 0;
  //                 left: 0;
  //                 width: 100%;
  //                 font-size: 11px;
  //                 height: 100%;
  //                 color: #FFFFFF;
  //                 padding: 0 0 5px 20px;
  //                 box-sizing: border-box;
  //                 display: flex;
  //                 align-items: center;

  //                 .content {
  //                     width: 50%;
  //                     display: flex;
  //                     align-items: center;
  //                     overflow: hidden;
  //                     text-overflow: ellipsis;
  //                     display: -webkit-box;
  //                     -webkit-line-clamp: 2;
  //                     -webkit-box-orient: vertical;
  //                 }

  //             }
  //         }
  //     }
  // }
}
</style>